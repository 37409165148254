<script>
  import { emailValidator, requiredValidator } from "./validators.js";
  import { createFieldValidator } from "./validation.js";
  import { createEventDispatcher } from "svelte";
  import { onMount, onDestroy } from "svelte";
  export let showRecaptcha = false;

  onMount(() => {
    if (showRecaptcha) window.onSubmit = onSubmit;
  });

  onDestroy(() => {
    if (showRecaptcha) window.onSubmit = null;
  });
  const dispatch = createEventDispatcher();
  const [validity, validate] = createFieldValidator(
    requiredValidator(),
    emailValidator()
  );

  let action = "/api/signup";
  let firstname = "";
  let lastname = "";
  let email = null;
  let result = null;
  let showConfirmation = false;
  async function onSubmit(token) {
    console.log(`executing! ${token}`);
      const res = await fetch(action, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `firstname=${firstname}&lastname=${lastname}&email=${encodeURIComponent(email)}`,
      });

      const json = await res.json();
      result = JSON.stringify(json);
      setCookie("subOnDevice", json.id,60);
      dispatch("success", {
        signed: true,
      });
      showConfirmation = true;
   }


  async function handleSubmit(event) {
    
    console.log(`firstname=${firstname}&lastname=${lastname}&email=${encodeURIComponent(email)}`);
    if(showRecaptcha) { grecaptcha.execute();}
    else{onSubmit('norecaptcha');}
    /*const res = await fetch(action, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `firstname=${firstname}&lastname=${lastname}&email=${encodeURIComponent(email)}`,
    });

    const json = await res.json();
    result = JSON.stringify(json);
    setCookie("subOnDevice", json.id,30);
    dispatch("success", {
      signed: true,
    });*/
  }

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000); //30days
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";SameSite=Lax;path=/";
  }
</script>

<style>
  .validation-hint {
    color: red;
    padding: 6px 0;
  }

  .field-danger {
    border-color: red;
  }

  .field-success {
    border-color: green;
  }
</style>

{#if showConfirmation}
  <h3 style="background-color: lavenderblush;">Thank you for signing up for our newsletter!  We will keep you in the loop!</h3>
{:else}
<form {action} on:submit|preventDefault={handleSubmit}>
  <div class="form-group" style="margin-bottom:2em;">
    <label class="form-label" for="email">Email</label>
    <input
      class="form-input"
      type="text"
      name="email"
      id="email"
      bind:value={email}
      placeholder="Enter your email address."
      class:field-danger={!$validity.valid}
      class:field-success={$validity.valid}
      use:validate={email} />
    {#if $validity.dirty && !$validity.valid}
      <span class="validation-hint">INVALID - {$validity.message}</span>
    {/if}
  </div>
  <div class="form-group" style="margin-bottom:2em;">
    <label class="form-label" for="firstname">First Name</label>
    <input
      class="form-input"
      type="text"
      name="firstname"
      id="firstname"
      bind:value={firstname}
      placeholder="Enter your firstname (optional)." />
  </div>
  <div class="form-group" style="margin-bottom:2em;">
    <label class="form-label" for="lastname">Last Name</label>
    <input
      class="form-input"
      type="text"
      name="lastname"
      id="lastname"
      bind:value={lastname}
      placeholder="Enter your lastname (optional)." />
  </div>
  <div>
    <!-- then pass the name of function 'verifyUser' just as string' -->
    {#if showRecaptcha}
    <div id='recaptcha' class="g-recaptcha"
    data-sitekey="6Let0MMZAAAAAPm0GvzFpQCVt3iclaAO8mH7WVz6"
    data-callback="onSubmit"
    data-size="invisible"></div>
    {/if}
    <button type="submit" disabled={!$validity.valid} class="btn btn-primary">
      Keep me in the loop!
    </button>
  </div>

</form>
{/if}