<script>
    export let transcript = "{the transcript}";
    export let audioUrl = "https://traffic.libsyn.com/secure/mrsbsbooks/podcast1BackToSchool.mp3";

    var subtitlesDiv = document.getElementById("subtitles");
    
</script>

<audio id="audiofile" src="{audioUrl}" controls>
    <track kind="captions">
</audio>

<div id="subtitles">
    {@debug transcript}
    {#each transcript as alt, i}
            {#each alt.alternatives[0].words as word, x}
                <span id="c_{i}_{x}" >{word[x]}</span>
            {/each}
    {/each}
</div>