/*import App from "./navigation.svelte";

const app = new App({
  target: document.getElementById("main-nav"),
  props: {},
});

export default app;
*/
import HeaderComponent from "./header.svelte";
import FooterComponent from "./footer.svelte";
import MenuComponent from "./menu.svelte";
import ContactUsFormComponent from "./contact-form.svelte";
import ReadingListComponent from "./reading-list.svelte";
import PodcastComponent from "./podcast-player.svelte";

window.HeaderComponent = function (options) {
  return new HeaderComponent(options);
};
window.FooterComponent = function (options) {
  return new FooterComponent(options);
};
window.MenuComponent = function (options) {
  return new MenuComponent(options);
};
window.ContactUsFormComponent = function (options) {
  return new ContactUsFormComponent(options);
};

window.ReadingListComponent = function (options) {
  return new ReadingListComponent(options);
};

window.PodcastComponent = function (options){
  return new PodcastComponent(options);
}