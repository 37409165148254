
<script>
    let navitems = [
		{url: "reading-lists.html", name:"Book Lists"},
		{url: "about-us.html", name: "About Us"},
		{url: "newsletter-sign-up.html", name:"Sign Up"}
	];
</script>

<!-- Header -->
<h1><a href="index.html"><img src="images/mrsbsbooks_banner5.png" alt="Mrs. B's Books" height="100%" style="padding-bottom: .5em;" /></h1>
<nav class="links">
    <ul>
    {#each navitems as {url,name}}
        <li><a href="{url}">{name}</a></li>
    {/each}
    </ul>
</nav>
<nav class="main">
    <ul>
        <li class="menu">
            <a class="fa-bars" href="#menu">Menu</a>
        </li>
    </ul>
</nav>

